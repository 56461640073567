import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import axios from 'axios';
// import https from 'https';

class AuthPage extends React.Component {
  componentDidMount() {
    const auth_check = async () => {
      const auth = localStorage.getItem('auth');
      if (auth != undefined) {
        // alert('비정상 접근입니다');
        // localStorage.removeItem('auth');
        this.props.history.push('/inference');
      }
      const auth_json = JSON.parse(auth);
    };
    auth_check();
  }

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    // this.props.history.push('/');
  };

  handle_login = async (id, password) => {
    // console.log('login', id, password);
    // const result = await axios.post('http://evankim.mooo.com:8055/api/login', {
    const result = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/login`,
      {
        id: id,
        password: password,
      },
    );
    if (result.data.code == 200) {
      const data = JSON.stringify({
        id: id,
        users_index: result.data.result.index,
      });
      localStorage.setItem('auth', data);
      this.props.history.push('/');
    } else {
      alert('로그인 정보를 다시 입력해주세요');
    }
  };

  handle_signup = async (id, password, password_confirm, policy) => {
    // console.log('signup', id, password, password_confirm, policy);
    if (password != password_confirm) {
      alert('비밀번호가 일치하지 않습니다');
    } else if (policy == false) {
      alert('이용약관을 수락해주세요');
    } else {
      const result = await axios.post(
        // 'http://evankim.mooo.com:8055/api/signup',
        `${process.env.REACT_APP_API_BASE_URL}/api/signup`,
        {
          id: id,
          password: password,
          password_confirm: password_confirm,
        },
      );
      if (result.data.code == 200) {
        alert('회원가입이 완료되었습니다');
        this.props.history.push('/login');
      } else {
        alert('회원가입을 다시 진행해주세요');
      }
    }
  };

  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              handle_login={this.handle_login}
              handle_signup={this.handle_signup}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
